<template>
  <div>
    <!-- Cargando -->
    <div v-if="loading">
      <loader
        object="#4caf50"
        color1="#ffffff"
        color2="#17fd3d"
        size="5"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="40"
        disableScrolling="false"
        name="box"
      ></loader>
    </div>
    <v-dialog v-model="dialog" max-width="600px" persistent>
      <v-card>
        <v-card-title
          style="font-family: 'Montserrat';"
          class="font-weight-bold"
        >
          {{ editar ? "Editar" : "Nuevo" }} Usuario Bloqueado
          <v-spacer></v-spacer>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <!-- BOTON X -->
              <v-btn @click="closeForm" icon v-bind="attrs" v-on="on">
                <v-icon>close</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-card-title>
        <v-card-text>
          <div>
            <v-form ref="formBloq" v-model="valid" lazy-validation>
              <!-- 1. Nombre -->
              <v-text-field
                v-model="nombre"
                label="Nombre"
                :rules="rules_nombre"
                required
              ></v-text-field>
              <!-- 2. Email -->
              <v-text-field
                v-model="email"
                label="Email"
                :rules="rules_email"
                required
              ></v-text-field>
              <!-- 3.DNI -->
              <v-text-field
                v-model="dni"
                label="DNI"
                type="number"
                :rules="rules_dni"
                required
              ></v-text-field>
              <!-- 4.CUIT -->
              <v-text-field
                v-model="cuit"
                label="CUIT"
                type="number"
                :rules="rules_cuit"
                required
              ></v-text-field>
              <!-- 5. Permanente -->
              <v-checkbox
                v-model="permanente"
                label="Permanente"
                class="my-3"
              ></v-checkbox>
              <v-select
                :items="cuentas"
                label="Cuenta"
                item-value="id"
                v-model="cuenta_id"
              ></v-select>
              <!-- 6. Cantidad de bloqueos -->
              <v-text-field
                v-model="cant_bloqueo"
                label="Cantidad de bloqueos"
                type="number"
                :rules="rules_cant_bloqueo"
                required
                :min="1"
              ></v-text-field>
              <!-- 7. Fecha de bloqueo -->
              <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fecha_bloqueo"
                    label="Fecha de bloqueo"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="rules_fecha_bloqueo"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fecha_bloqueo"
                  no-title
                  scrollable
                  :max="new Date().toISOString().substr(0, 10)"
                >
                </v-date-picker>
              </v-menu>

              <!-- BOTONES -->
              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- BOTON Cerrar -->
                <v-btn color="grey darken-1" text @click="closeForm">
                  Cerrar
                </v-btn>
                <!-- BOTON Guardar -->
                <v-btn
                  :disabled="!valid"
                  color="green"
                  class="font-weight-bold"
                  style="font-family: 'Montserrat';"
                  text
                  @click="editar ? editarUsuarioBloq() : crearUsuarioBloq()"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { userBloqService } from "@/services/administrar";
import { clientesService } from "@/services/administrar";

export default {
  data: () => ({
    dialog: false,

    editar: false,
    valid: false,
    loading: false,

    //nuevo usuario bloqueado
    nombre: "",
    email: "",
    dni: "",
    cuit: "",
    permanente: false,
    cuenta_id: "",
    cuenta: "",
    cuentas: [],
    cant_bloqueo: 1,
    fecha_bloqueo: "",

    //id para actualizar
    id: null,

    // VALIDACIONES -------------------------------------------
    rules_nombre: [
      (v) => !!v || "El campo Nombre es requerido",
      (v) =>
        (v && v.length <= 30) ||
        "El campo  Nombre no debe superar los 30 caracteres",
    ],
    rules_email: [
      (v) => !!v || "El campo Email es requerido",
      (v) => /.+@.+\..+/.test(v) || "Email no es valido",
    ],
    rules_cant_bloqueo: [
      (v) => !!v || "El campo Cantidad de bloqueos es requerido",
      (v) =>
        (v && v >= 1) || "El campo Cantidad de bloqueos debe ser mayor a 0",
    ],
    rules_fecha_bloqueo: [
      (v) => !!v || "El campo Fecha de bloqueo es requerido",
    ],
    rules_dni: [
      (v) => !!v || "El campo DNI es requerido",
      (v) =>
        (v && v.length <= 8) || "El campo DNI no debe superar los 8 caracteres",
    ],
    rules_cuit: [
      (v) => !!v || "El campo CUIT es requerido",
      (v) =>
        (v && v.length <= 11) ||
        "El campo CUIT no debe superar los 11 caracteres",
    ],
  }),
  mounted() {
    this.$on("showDialogBloqNuevo", () => {
      this.dialog = true;
      this.editar = false;
    });
    this.$on("showFormEditBloq", (item) => {
      this.dialog = true;
      this.editar = true;
      this.nombre = item.nombre;
      this.dni = item.dni;
      this.cuit = item.cuit;
      this.email = item.email;
      this.permanente = item.permanente;
      this.cant_bloqueo = item.cant_bloqueo;
      this.fecha_bloqueo = item.fecha_bloqueo.substr(0, 10);

      this.id = item.id;
    });
    //Para traer las cuentas de los clientes
    this.getClientes();
  },
  methods: {
    reset() {
      this.$refs.formBloq.reset();
    },
    limpiar_campos() {
      this.nombre = "";
      this.email = "";
      this.permanente = false;
      (this.cuenta_id = ""), (this.cant_bloqueo = 1);
      this.fecha_bloqueo = "";
    },
    closeForm() {
      this.limpiar_campos();
      this.dialog = false;
      this.$emit("refreshUsuariosBloq");
      this.reset();
    },

    async crearUsuarioBloq() {
      const payload = {
        nombre: this.nombre,
        email: this.email,
        dni: this.dni,
        cuit: this.cuit,
        permanente: this.permanente,

        //Para guardar la cuenta y id
        cuenta_id: this.cuenta_id,

        cant_bloqueo: parseInt(this.cant_bloqueo),
        fecha_bloqueo: this.fecha_bloqueo + "T00:00:00-03:00",
      };
      if (this.$refs.formBloq.validate()) {
        try {
          this.loading = true;
          await userBloqService.newBloqUser(payload);
          this.loading = false;
          this.$toastr.s("Usuario bloqueado añadido con exito");

          this.closeForm();
        } catch (error) {
          this.loading = false;
          this.$toastr.e("Error al intentar añadir usuario bloqueado");
        }
        this.$emit("refreshUsuariosBloq");
      }
    },

    async editarUsuarioBloq() {
      const payload = {
        Id: this.id,
        nombre: this.nombre,
        dni: this.dni,
        cuit: this.cuit,
        cuenta_id: this.cuenta_id,
        email: this.email,
        permanente: this.permanente,
        cant_bloqueo: parseInt(this.cant_bloqueo),
        fecha_bloqueo: this.fecha_bloqueo + "T00:00:00-03:00",
      };
      try {
        this.loading = true;
        await userBloqService.updateBloqUser(payload);
        this.loading = false;
        this.$toastr.s("Usuario bloqueado actualizado con exito");
        this.closeForm();
      } catch (error) {
        this.loading = false;
        // console.error(error);
        this.$toastr.e("Error al intentar actualizar usuario bloqueado");
      }
      this.$emit("refreshUsuariosBloq");
    },

    //Para traer todos los clientes y mostrar las cuentas
    async getClientes() {
      const params = {
        CargarImpuestos: false,
        CargarCuentas: true,
        CargarRubros: false,
      };
      //Traigo los datos de los clientes en data
      const { data } = await clientesService.obtenerClientes(params);
      //Tengo almacenado los datos de mis clientes en el arreglo this.clientes
      this.clientes = data;

      // Funcion para el select de Cuenta, hago un mapeo para traer todas las cuentas y lo guardo en mi arreglo "cuentas[]"
      this.cuentas = this.clientes
        .map((cliente) => {
          // Mapeamos todas las cuentas del cliente y devolvemos un arreglo de objetos con la información de cada cuenta
          return cliente.cuenta.map((cuenta) => {
            return {
              id: cuenta.id, // Utilizamos el id de la cuenta
              text: `${cuenta.cuenta}`, // Creamos el texto con el id del cliente y el nombre de la cuenta
            };
          });
        })
        .flat(); // Uso flat porque dentro de mi arreglo cuentas hay arreglos que serian de los clientes con mas cuentas.Lo que hace es ponerlos todos al mismo nivel.
    },
  },
};
</script>

<style scoped></style>
